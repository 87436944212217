body {
    & * {
        font-family: Lato !important;
    }

    .react-datepicker-wrapper {
        width: 100%;
        height: 100%;
        .react-datepicker__input-container {
            width: 100%;
            height: 100%;
            input {
                width: 100%;
                height: 100%;
                font-weight: normal;
                font-size: 1rem;
                border: none;
                &:focus {
                    outline: none !important;
                }
            }
        }

        .react-datepicker__close-icon::after {
            cursor: pointer;
            color: #78778b;
            background-color: transparent;
            height: 16px;
            width: 16px;
            padding: 2px;
            font-size: 20px;
            line-height: 1;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            content: '\00d7';
        }
    }

    .react-datepicker {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        border: none;
        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__day--disabled {
            color: #bbb !important;
        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 95px !important;
        }

        .react-datepicker__header {
            background: white !important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            .react-datepicker__current-month,
            .react-datepicker-time__header {
                font-size: 1rem;
                color: #404040 !important;
                font-weight: 500 !important;
            }
        }

        .react-datepicker__day--selected,
        .react-datepicker__time-list-item--selected {
            background: #5e81f4 !important;
            color: white !important;
        }

        .react-datepicker__day--today,
        .react-datepicker__day--keyboard-selected {
            border-radius: 0px !important;
            background: #e1dae8 !important;
            color: #1c1d21;
            font-weight: normal;
        }

        .react-datepicker__time-list-item {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .react-datepicker__day {
            color: #1c1d21;
            border-radius: 50% !important;
            font-size: 0.875rem;
            width: 36px;
            height: 36px;
            line-height: 36px;
            &:hover {
                border-radius: '0px !important';
            }
        }

        .react-datepicker__time-container--with-today-button {
            right: -85px !important;
            border: none;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
        }

        .react-datepicker__today-button {
            background: white !important;
            font-size: 16px;
            font-weight: normal;
            font-family: Roboto;
            padding: 10px 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
        }

        .react-datepicker__day-names {
            .react-datepicker__day-name {
                font-weight: 500 !important;
            }
        }
    }
}
