.DateRangePickerInput__withBorder {
    border: 1px solid #ececf2;
    border-radius: 4px;
}

.DateInput_input__focused {
    border-bottom: 1px solid #5e81f4;
}

.DateInput {
    width: 110px;
}
.DateInput_input {
    font-size: 15px;
    color: black;
    font-weight: 400;
}

.DateRangePickerInput_arrow svg {
    width: 20px;
    height: 20px;
}

.CalendarDay__selected_span {
    background: #2c5db5;
    color: white;
    border: 1px solid #ececf2;
}

.CalendarDay__selected {
    background: #68b8fc;
    color: white;
}

.CalendarDay__selected:hover {
    background: #ffdbf7;
    color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #a3d4ff;
}
